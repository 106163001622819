@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Mythshire';
    src: url('../public/fonts/MythshireRegular.woff2') format('woff2'),
        url('../public/fonts/MythshireRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}